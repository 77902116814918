import {useLocation} from "@reach/router";
import {graphql} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../../../components/Layout";
import BlogGallery from "../BlogGallery/BlogGallery";
import "./Tag.scss";

const Tag = ({data, pageContext}) => {
  const {
    data: {name, feature_image: featureImage, description, localImage} = {},
  } = pageContext;
  const title = `Mason | Blog - ${name}`;
  return (
    <Layout>
      <Helmet title={title} htmlAttributes={{lang: "en"}}>
        <meta property='og:type' content='article' />
        <meta property='og:url' content={useLocation()} />
        <meta property='og:title' content={name} />
        <meta property='og:image' content={featureImage} />
        <meta property='og:description' content={description} />
      </Helmet>
      <div className='full-bleed layouted bg-gray-200'>
        <div className='tag-header flex flex-col md:flex-row items-center mt-36 mb-12'>
          <div className='tag-icon mr-4 p-4 shadow-md'>
            <GatsbyImage image={localImage.childImageSharp.gatsbyImageData} />
          </div>
          <div className='tag-content w-full md:w-2/4 p-4 md:px-0'>
            <h1 className='md:text-left'>{name}</h1>
            <span>{description}</span>
          </div>
        </div>
        <BlogGallery data={data.allGhostPost.nodes} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query GhostTagPosts($tag: String) {
    allGhostPost(filter: {tags: {elemMatch: {slug: {eq: $tag}}}}) {
      nodes {
        feature_image
        localImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        featured
        id
        tags {
          name
          slug
        }
        title
        authors {
          profile_image
          name
        }
        reading_time
        slug
        html
        published_at
      }
    }
  }
`;

export default Tag;
